import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ColorUse from '../../assets/theme/ColorUse';

import Zoom from 'react-medium-image-zoom'
import { makeStyles } from '@mui/styles';

export default function ShelfPromotion({data=[]}) {
    return (
    <Swiper
        navigation={true}
        loop={true}
        slidesPerView={4}
        spaceBetween={20}
        modules={[Navigation, Pagination, Autoplay]}
        hashNavigation={{
            watchState: true,
        }}
        pagination={{
            clickable: true,
        }}
        autoplay={{
            delay: 3500,
        }}
        breakpoints={{
            0: {
            slidesPerView: 1,
            spaceBetween: 15,
            },
            768: {
            slidesPerView: 3,
            spaceBetween: 20,
            },
            1024: {
            slidesPerView: 4,
            spaceBetween: 20,
            },
        }}
        className="carousel-custom"
        >
        {data.map((item, idx) => {
            return (
            <SwiperSlide key={idx}>
                <CardImage data={item} />
            </SwiperSlide>
            );
        })}
    </Swiper>
    )
};


const CardImage = props => {
    const classes = useClasses();
    return (
    <Paper
        elevation={0}
        sx={[styleCard]}
    >
        <Box sx={{
            width:'100%',
            height:'100%',
            '& [data-rmiz]':{
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                '& [data-rmiz-content]':{
                    height:'100%',

                }
            }
        }}>
            <Zoom
            zoomMargin={20}
            classDialog={classes.backdropAppZoom}
            >
                <img src={props?.data} alt={props?.data}
                style={{
                    maxWidth:"100%",
                    height:'100%',
                    objectFit:'cover',
                    transition:'all 0.25s',
                }}
                className="image-promotion-app"
                />
            </Zoom>
        </Box>
    </Paper>
    )
}

const useClasses = makeStyles(()=>({
    backdropAppZoom:{
        '& [data-rmiz-modal-overlay="visible"]':{
            background:"rgba(0,0,0,0.75)"
        },
    }
}));

const styleCard = {
    // width: '100%',
    cursor: "pointer",
    transition: "all 0.35s",
    border: "1px solid #efefef",
    p:0,
    height: {
      xs: 'auto',
      md: 'auto',
      lg: 340,
    },
    mb: 7,
    ":hover": {
    //   transform: "translate(0px, -5px)",
      transform: "scale(1.005)",
      // border: '1px solid '+ColorUse.colorPrimary,
      boxShadow: "2px 5px 10px 0px rgba(0,0,0,0.05)",
      "& .t-text-wrap-3": {
        color: ColorUse.colorPrimary,
      },
      '& img':{
        transform: "scale(1.005)",
      }
    },
  };