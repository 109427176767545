import {
  FormatListBulleted,
  PictureAsPdfOutlined,
  WarningAmber,
  Widgets,
} from "@mui/icons-material";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Paper,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CategoryMenu from "../components/category/CategoryMenu";
import ListCateItem from "../components/category/ListCateItem";
import { getCategoryList } from "../services/list_category_service";
import { getCategory } from "../services/category_service";
// import { getProducts } from "../services/product_service";
import { getProductbyCategory } from "../services/product";
import { getPublicData } from "../services/brand";
import PaginationCategory from "../components/PaginationCategory";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRef } from "react";
import getPaginate from "../Utils/getPaginate";
import ColorUse from "../assets/theme/ColorUse";

const styleCard = {
  // width: '100%',
  cursor: "pointer",
  transition: "all 0.35s",
  border: "1px solid #efefef",
  p: 2,
  height: {
    xs: 300,
    md: 300,
    lg: 350,
  },
  ":hover": {
    transform: "scale(1.015)",
    // border: '1px solid '+ColorUse.colorPrimary,
    boxShadow: "2px 5px 10px 0px rgba(0,0,0,0.05)",
    "& .t-text-wrap-3": {
      color: ColorUse.colorPrimary,
    },
  },
  "& img.t-img-product": {
    width: "100%",
    height: {
      xs: 180,
      lg: 230,
    },
    objectFit: "contain",
  },
};
const styleOtherCate = {
  marginBottom: 1,
  display: "block",
  textTransform: "uppercase",
  lineHeight: 1.7,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
// ================================= function main
function Category() {
  // const [sortBy, setSortBy] = useState("popularity");
  // const [countProductShow, setcountProductShow] = useState(10);
  const [showDataGrid, setShowDataGrid] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  const navigate = useNavigate();

  const [products, setproducts] = useState([]);
  const [productsShow12, setProductsShow12] = useState([]);
  const [menuCategory, setMenuCategory] = useState([]);

  const loopSetMenu = (data, idx, cates) => {
    return data.map((sub, idx_sub) => {
      return {
        categoryId: cates.find((cate) => cate.category_name === sub.name).id,
        id_node: `${idx}-${idx_sub}`,
        node_parent: idx,
        id: idx_sub,
        title: sub.name,
        path: "/category/" + sub.name,
        sub_menu: [],
      };
    });
  };

  const [dataShow, setDataShow] = useState({
    brand: [],
    category: [],
  });
  const [loader, setLoader] = useState(true);
  const [loaderProducts, setLoaderProducts] = useState(true);

  React.useEffect(() => {
    const fetchData = () => {
      setLoader(true);
      getPublicData().then((res) => {
        setDataShow(res);
        // console.log(res);
        setLoader(false);
        // if (!res.brand.includes(null)) {
        //   setLoader(false);
        // } else {
        //   fetchData();
        // }
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    let cates = [];
    getCategory().then((res) => {
      cates = res;
      setCategoryList(res);
      // console.log("Category => ", res);
      getCategoryList().then((res) => {
        let newData = [];
        res.map((item, idx) => {
          newData.push({
            id_node: `${idx}`,
            node_parent: null,
            id: idx + 1,
            title: item.name,
            path: "/category/" + item.name,
            categoryId: cates.find((cate) => cate.category_name === item.name)
              .id,
            sub_menu: item.sub_list.map((sub, idx_sub) => {
              return {
                id_node: `${idx}-${idx_sub}`,
                node_parent: `${idx}`,
                id: idx_sub + 1,
                title: sub.name,
                path: "/category/" + sub.name,
                categoryId: cates.find(
                  (cate) => cate.category_name === item.name
                ).id,
                sub_menu: loopSetMenu(sub.sub_list, `${idx}-${idx_sub}`, cates),
              };
            }),
          });
        });
        // console.log("newData", newData);
        setProductCategory(newData);

        // console.log(res);
        setMenuCategory(
          res.map((list, idx) => {
            return {
              id: idx + 1,
              title: list.name,
              main_path: "/category/" + list.name,
              list: list.sub_list.map((sub) => {
                return {
                  listTitle: sub.name,
                  path: "/category/" + sub.name,
                };
              }),
            };
          })
        );
      });
    });
  }, []);

  const paramsCategory = useParams();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") !== null ? searchParams.get("page") : 1
  );
  const categoryId = useRef(paramsCategory.categoryId);

  // for concat cateIds
  useEffect(() => {
    if (paramsCategory.categoryId) {
      categoryId.current = paramsCategory.categoryId;
    }
    if (paramsCategory.categoryIdSub1) {
      categoryId.current = [
        paramsCategory.categoryId,
        paramsCategory.categoryIdSub1,
      ].join(",");
    }
    if (paramsCategory.categoryIdSub2) {
      categoryId.current = [
        paramsCategory.categoryId,
        paramsCategory.categoryIdSub1,
        paramsCategory.categoryIdSub2,
      ].join(",");
    }
  }, [paramsCategory]);

  // for fetch data first load component
  useEffect(() => {
    setLoaderProducts(true);
    if (categoryId.current) {
      getProductbyCategory(categoryId.current, 1, 99999999, false, false).then(
        (res) => {
          setproducts(res.data);
          setProductsShow12(
            getPaginate(
              res.data,
              12,
              searchParams.get("page") !== null ? searchParams.get("page") : 1
            )
          );
          setLoaderProducts(false);
        }
      );
    } else {
      getProductbyCategory("", 1, 99999999, false, false).then((res) => {
        setproducts(res.data);
        setProductsShow12(
          getPaginate(
            res.data,
            12,
            searchParams.get("page") !== null ? searchParams.get("page") : 1
          )
        );
        setLoaderProducts(false);
      });
    }
  }, []);

  const handleChangePagination = useCallback(
    (valPage) => {
      setProductsShow12(getPaginate(products, 12, valPage));
      setPage(valPage);
      navigate(`${window.location.pathname}?page=${valPage}`);
    },
    [products, navigate]
  );

  const handleChangeCategory = (id, name) => {
    setPage(1);
    navigate("/category/" + id);
    categoryId.current = id;
    getProductbyCategory(id, 1, 999999999, false, false).then((res) => {
      setproducts(res.data);
      setProductsShow12(getPaginate(res.data, 12, 1));
    });
  };

  const fetApiProductsBySearch = useCallback(
    (arrCate) => {
      if (arrCate?.length > 0) {
        getProductbyCategory(arrCate.join(","), 1, 9999999, false, false).then(
          (res) => {
            navigate("/category/" + arrCate.join("/"));
            setproducts(res.data);
            setProductsShow12(getPaginate(res.data, 12, 1));
          }
        );
      } else {
        getProductbyCategory("", 1, 99999999, false, false).then((res) => {
          navigate("/category");
          setproducts(res.data);
          setProductsShow12(
            getPaginate(
              res.data,
              12,
              searchParams.get("page") !== null ? searchParams.get("page") : 1
            )
          );
        });
      }
    },
    [navigate, searchParams]
  );

  const handleLinkGetId = useCallback(
    (id_node, id = null) => {
      setLoaderProducts(true);
      /** @type array */
      const arrFindIdCate = id_node.split("-");
      let cateIdMain, cateIdSub1, cateIdSub2, strArr;
      if (arrFindIdCate.length === 1) {
        cateIdMain = productCategory[arrFindIdCate[0]];
        strArr = [cateIdMain.categoryId];
      } else if (arrFindIdCate.length === 2) {
        cateIdMain = productCategory[arrFindIdCate[0]];
        cateIdSub1 =
          productCategory[arrFindIdCate[0]].sub_menu[arrFindIdCate[1]];
        strArr = [cateIdMain.categoryId, cateIdSub1.categoryId];
      } else if (arrFindIdCate.length === 3) {
        cateIdMain = productCategory[arrFindIdCate[0]];
        cateIdSub1 =
          productCategory[arrFindIdCate[0]].sub_menu[arrFindIdCate[1]];
        cateIdSub2 =
          productCategory[arrFindIdCate[0]].sub_menu[arrFindIdCate[1]].sub_menu[
            arrFindIdCate[2]
          ];
        strArr = [
          cateIdMain.categoryId,
          cateIdSub1.categoryId,
          cateIdSub2.categoryId,
        ];
      }
      // getProductbyCategory(strArr.join(","), 1, 9999999, false, false).then(
      getProductbyCategory(
        strArr[strArr.length - 1],
        1,
        9999999,
        false,
        false
      ).then((res) => {
        // navigate("/category/" + strArr.join("/"));
        navigate("/category/" + strArr[strArr.length - 1]);
        setproducts(res.data);
        setProductsShow12(getPaginate(res.data, 12, 1));
        setLoaderProducts(false);
      });
    },
    [productCategory, navigate]
  );

  return (
    <Box
      className="container-main"
      sx={{ paddingX: { xs: 2, xl: 5 }, paddingY: { xs: 3, xl: 5 } }}
    >
      {/* phase 1 */}
      <Grid
        container
        flexDirection={{ xs: "column-reverse", lg: "row" }}
        spacing={5}
        sx={{ marginBottom: 8 }}
      >
        {/* Category Menu */}
        <Grid item xs={12} lg={3}>
          <Box sx={{ height: "100%" }}>
            {menuCategory.length > 0 ? (
              <CategoryMenu
                brand={products}
                data={productCategory}
                onChangeCategory={(id, name) => handleChangeCategory(id, name)}
                dataShow={dataShow}
                loader={loader}
                allCategory={categoryList}
                handleLinkGetId={handleLinkGetId}
                fetApiProductsBySearch={fetApiProductsBySearch}
              />
            ) : (
              <></>
            )}
          </Box>
        </Grid>

        {/* Content Products Show */}
        <Grid item xs={12} lg={9}>
          <Box>
            <Grid container>
              {/* filter */}
              <Grid item xs={12} mb={5}>
                <Box
                  sx={{
                    pb: 2,
                    display: { xs: "block", sm: "flex" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "0.5px solid #e0e0e0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <label htmlFor="seleted-sort" style={{ marginRight: 5 }}>
                      Display :
                    </label>
                    <Button
                      onClick={() => setShowDataGrid(!showDataGrid)}
                      className={"t-text-primary"}
                    >
                      {showDataGrid ? (
                        <FormatListBulleted sx={{ color: "inherit" }} />
                      ) : (
                        <Widgets sx={{ color: "inherit" }} />
                      )}
                    </Button>
                  </div>
                  <div>
                    <Button
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/APK_Product_Catalog.pdf?alt=media&token=98e98a4b-aa09-44ed-966a-4cb960bdb4eb"
                      }
                      target={"_blank"}
                      sx={{
                        background: ColorUse.colorPrimary,
                        border: "2px solid " + ColorUse.colorPrimary,
                        color: "#fff",
                        "&:hover": {
                          color: ColorUse.colorPrimary,
                          background: "#0000",
                        },
                      }}
                    >
                      <PictureAsPdfOutlined sx={{ marginRight: 0.5 }} />{" "}
                      ดาวน์โหลดแคตตาล็อกทั้งหมด
                    </Button>
                  </div>
                </Box>
              </Grid>

              {/* list product */}
              <Grid item xs={12}>
                {!loaderProducts ? (
                  productsShow12.length > 0 ? (
                    <Box>
                      <ListCateItem
                        data={productsShow12}
                        showGrid={showDataGrid}
                        loader={loader}
                        loaderProducts={loaderProducts}
                        dataShow={categoryList}
                        handleChangeCategory={(idcat, namecat) =>
                          handleChangeCategory(idcat, namecat)
                        }
                      />
                      {products.length > 12 ? (
                        <PaginationCategory
                          key={2}
                          pagination={{
                            total: products?.length,
                            pageSize: 12,
                            pageNumber: parseInt(page),
                          }}
                          value={parseInt(page)}
                          onChange={(val) => {
                            // console.log(val);
                            handleChangePagination(val);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: "center", color: "#808080" }}>
                      <WarningAmber sx={{ width: 100, height: 100 }} />
                      <p>ไม่พบข้อมูลที่ต้องการ</p>
                    </Box>
                  )
                ) : (
                  <Grid container spacing={3} mt={0.5}>
                    {Array.from(new Array(12)).map((item, index) => (
                      <Grid item key={index} xs={6} md={4} lg={3} mb={3}>
                        <Paper
                          elevation={0}
                          sx={[
                            styleCard,
                            {
                              "&:hover": {
                                borderColor: "#efefef",
                                boxShadow: "none",
                              },
                            },
                          ]}
                        >
                          <Box mb={6}>
                            <Skeleton
                              variant="rounded"
                              width={"100%"}
                              height={180}
                            />
                          </Box>
                          <Box>
                            <Box marginBottom={1}>
                              <Skeleton
                                variant="rounded"
                                width={"100%"}
                                height={20}
                              />
                            </Box>
                            <Box sx={[styleOtherCate]} textAlign={"center"}>
                              <Skeleton
                                variant="rounded"
                                width={"100%"}
                                height={20}
                              />
                            </Box>
                            <Skeleton
                              variant="rounded"
                              width={"100%"}
                              height={60}
                            />
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Category;
