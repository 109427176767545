import {
  ExpandMore,
  Search,
  ExpandLess,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
  Skeleton,
  ListItem,
  IconButton,
  Collapse,
  List,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ColorUse from "../../assets/theme/ColorUse";
// import $ from 'jquery';

const style = {
  callapseHead: {
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "none",
  },
  linkMenu: {
    color: "#696969",
    ":hover": { color: ColorUse.colorPrimary },
    textDecoration: "none",
    width: "100%",
    height: "100%",
  },
  badegeBrand: {
    width: "100%",
  },
};

// ================ function main
function CategoryMenu({
  data = [
    {
      id_node: "",
      node_parent: null,
      id: 0,
      title: "",
      path: "",
      sub_menu: [],
    },
  ],
  brand = [
    {
      id_brand: 1,
      name: "",
      amount: 0,
    },
  ],
  onChangeCategory = () => {},
  dataShow = [],
  loader = false,
  allCategory = [],
  handleLinkGetId,
  fetApiProductsBySearch
}) {
  // const { categoryId } = useParams();
  // const [expanded, setExpanded] = React.useState([]);
  // const [selected, setSelected] = React.useState([]);

  // function stack list path Treeview
  // const createHandler = async (item, idNode, nodeParent) => {
  //   // console.log(`node: ${id} parent: ${nodeParent}`);
  //   if (selected === idNode) {
  //     setSelected([]);
  //   } else {
  //     setSelected(idNode);
  //   }
  //   if (!expanded.includes(idNode)) {
  //     const last_ar = expanded.filter(
  //       (row, idx) => idx === expanded.length - 1
  //     );
  //     if (last_ar[0] === nodeParent) {
  //       if (item.sub_menu.length > 0) setExpanded([...expanded, idNode]);
  //     } else if (last_ar.length === 0) {
  //       if (item.sub_menu.length > 0) setExpanded([...expanded, idNode]);
  //     } else {
  //       if (nodeParent === null) {
  //         setExpanded([idNode]);
  //       } else {
  //         setExpanded([nodeParent, idNode]);
  //       }
  //     }
  //   } else {
  //     setExpanded(
  //       expanded.filter((i) => i !== idNode && !i.startsWith(`${idNode}.`))
  //     );
  //   }
  // };

  const [expandCate, setExpandCate] = useState(true);
  const [expandBrand, setExpandBrand] = useState(true);

  // const TreeCusItem = (nodes) => {
  //   return (
  //     <TreeItem
  //       key={nodes.id_node}
  //       className="t-custom-listmenu"
  //       onClick={() => {
  //         createHandler(nodes, nodes.id_node, nodes.node_parent, nodes.path);
  //       }}
  //       nodeId={nodes.id_node}
  //       label={
  //         <Box
  //           sx={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "space-between",
  //             p: 0.5,
  //             pr: 0,
  //           }}
  //         >
  //           <Typography className="t-text-listmenu">{nodes.title}</Typography>
  //         </Box>
  //       }
  //     >
  //       {Array.isArray(nodes.sub_menu)
  //         ? nodes.sub_menu.map((node) => TreeCusItem(node))
  //         : null}
  //     </TreeItem>
  //   );
  // };

  const [valuesSelected, setValuesSelected] = useState([]);

  return data.length > 0 ? (
    <Box className="content-menu-category " sx={{ height: "100%" }}>
      {loader ? (
        <div>
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
          <Skeleton variant="rounded" sx={{marginBottom: 2}} width={"100%"} height={30} />
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: 3 }}>
            <Box display={"flex"} alignItems="center" flexDirection={"row"}>
              <Box sx={{ width: "80%" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="multiple-search-category"
                  className="t-multiselect"
                  options={allCategory}
                  value={valuesSelected}
                  getOptionLabel={(option) => option.category_name}
                  defaultValue={[]}
                  size={"small"}
                  onChange={(evt, value)=>{
                    setValuesSelected(value.slice(0,3))
                  }}
                  sx={{
                    maxWidth: "100%",
                    "& input.Mui-focused + .MuiOutlinedInput-notchedOutline": {
                      borderColor: "red",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder="Seach...." />
                  )}
                />
              </Box>
              <Button
                onClick={()=>{
                  if(valuesSelected?.length > 0){
                    fetApiProductsBySearch(valuesSelected.map(r=> r?.id ))
                  }else{
                    fetApiProductsBySearch([])
                  }
                }}
                variant="contained"
                sx={{
                  width: "20%",
                  height: "100%",
                  m: 0,
                  color: "#fff",
                  backgroundColor: ColorUse.colorPrimary + " !important",
                  ml: 1,
                  border: "2px solid " + ColorUse.colorPrimary,
                  
                }}
              >
                <Search />
              </Button>
            </Box>
          </Grid>

          {/* products */}
          <Grid item xs={12} marginBottom={3}>
            {/* <TreeView
              //   aria-label="controlled"
              defaultCollapseIcon={<Remove />}
              defaultExpandIcon={<Add />}
              //   expanded={expanded}
              expanded={expanded}
              selected={selected}
              //   onNodeToggle={(e, nodeIds)=>{}}
              //   onNodeSelect={handleSelect}
            >
              {data.map((row) => TreeCusItem(row))}
            </TreeView> */}
            <List>
              {data.map((row, idx) => {
                return (
                  <MultiMenu
                    key={"menu_" + idx}
                    item={row}
                    handleLinkGetId={handleLinkGetId}
                  />
                );
              })}
            </List>
            {/* <Box
              component={"div"}
              sx={{
                height: 3,
                width: { xs: "100%", lg: "100%" },
                background: "#efefef",
                maxWidth: "100%",
                marginBottom: 2,
              }}
            ></Box> */}
          </Grid>

          {/* Brand */}
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Accordion
              sx={style.callapseHead}
              expanded={expandBrand}
              // onChange={() => setExpandBrand(!expandBrand)}
            >
              <AccordionSummary
                sx={{
                  "&.MuiButtonBase-root": { minHeight: "auto" },
                  ".MuiAccordionSummary-content.Mui-expanded": {
                    color: ColorUse.colorPrimary,
                    margin: 0,
                  },
                }}
                id="brand_header"
                // expandIcon={<ExpandMore />}
                aria-controls="brand_header_content"
              >
                <Typography sx={{ textTransform: "uppercase" }}>
                  Brand
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component={"div"}
                  sx={{
                    height: 4,
                    width: { xs: "100%", lg: 100 },
                    background: "#efefef",
                    maxWidth: "100%",
                    marginBottom: 1,
                  }}
                ></Box>
                {dataShow.brand.sort((a, b)=>{
                  // console.log(a.name.trim().toLowerCase(), b.name.trim().toLowerCase());
                  return a.name.trim().toLowerCase() < b.name.trim().toLowerCase()
                }).map((listBrand, idx) => {
                  return (
                    <MenuItem
                      key={"brand_" + idx}
                      onClick={() => {
                        onChangeCategory(listBrand.id, listBrand.name);
                      }}
                      className="t-brand-link"
                    >
                      <Box sx={{ maxWidth: "100%", display: "flex" }}>
                        <Typography noWrap={true}>{listBrand.name} </Typography>
                        <span style={{ marginLeft: 5 }}>
                          ({listBrand.total})
                        </span>
                      </Box>
                    </MenuItem>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Categry */}
          {/* <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Accordion
              sx={style.callapseHead}
              expanded={expandCate}
              // onChange={() => setExpandCate(!expandCate)}
            >
              <AccordionSummary
                sx={{
                  "&.MuiButtonBase-root": { minHeight: "auto" },
                  ".MuiAccordionSummary-content.Mui-expanded": {
                    color: ColorUse.colorPrimary,
                    margin: 0,
                  },
                }}
                id="cate_header"
                // expandIcon={<ExpandMore />}
                aria-controls="cate_header_content"
              >
                <Typography sx={{ textTransform: "uppercase" }}>
                  Category
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component={"div"}
                  sx={{
                    height: 4,
                    width: { xs: "100%", lg: 100 },
                    background: "#efefef",
                    maxWidth: "100%",
                    marginBottom: 1,
                  }}
                ></Box>
                {dataShow.category.map((listproduct, idx) => {
                  // console.log(listproduct);
                  return (
                    <MenuItem
                      key={"brand_" + idx}
                      onClick={() =>
                        onChangeCategory(listproduct.id, listproduct.name)
                      }
                      className="t-brand-link"
                    >
                      <Box sx={{ maxWidth: "100%", display: "flex" }}>
                        <Typography noWrap={true}>
                          {listproduct.name}{" "}
                        </Typography>
                        <span style={{ marginLeft: 5 }}>
                          ({listproduct.total})
                        </span>
                      </Box>
                    </MenuItem>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Grid> */}
        </Grid>
      )}
    </Box>
  ) : (
    <Box></Box>
  );
}

const MultiMenu = ({
  item = {
    id_node: "",
    node_parent: "",
    id: 0,
    title: "",
    path: "",
    sub_menu: [],
  },
  handleLinkGetId,
}) => {
  const [expandCollapse, setExpandCollapse] = useState(false);

  return (
    <ListItem
      dense={true}
      className={"items-list"}
      sx={{ width: "100%", paddingRight: 0, paddingY: 0 }}
    >
      <Box sx={{ width: "100%" }} className="t-border-bottom">
        <Grid container className="border-list-item" sx={{ paddingY: 1 }}>
          <Grid item xs={10}>
            <Link
              className="link-category"
              onClick={() => handleLinkGetId(item?.id_node, item?.id)}
            >
              <Typography className="text-typo" noWrap={true}>
                {item.title}
              </Typography>
            </Link>
          </Grid>
          {item.sub_menu.length > 0 ? (
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "end", paddingX: 1 }}
            >
              <IconButton
                onClick={() => setExpandCollapse(!expandCollapse)}
                size="small"
                sx={{ padding: 0 }}
              >
                {expandCollapse ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
        {item.sub_menu.length > 0 ? (
          <Box>
            <Collapse in={expandCollapse} timeout={"auto"} unmountOnExit>
              <List className="t-submenu-list" disablePadding>
                {item.sub_menu.map((row, idx) => (
                  <MultiMenu
                    key={"submenu_" + idx}
                    item={row}
                    handleLinkGetId={handleLinkGetId}
                  />
                ))}
              </List>
            </Collapse>
          </Box>
        ) : null}
      </Box>
    </ListItem>
  );
};
export default CategoryMenu;
