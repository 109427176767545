import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Navbar from "./components/Navbar";
import AdminLogin from "./pages/AdminLogin";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Header from "./components/Header";
import Footer from "./components/Footer";
// Import Swiper styles
// import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./assets/css/custom.css";
import "./assets/css/responsive.css";
import Category from "./pages/Category";
import Basket from "./components/Basket";
import Product from "./pages/Product";
import ArticleNews from "./pages/ArticleNews";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

const firebaseConfig = {
  apiKey: "AIzaSyDWTVbq_cSmk6Tol9xcVofQm3FhhyZL80k",
  authDomain: "apk-scientific.firebaseapp.com",
  projectId: "apk-scientific",
  storageBucket: "apk-scientific.appspot.com",
  messagingSenderId: "54892122959",
  appId: "1:54892122959:web:eba41f298d77fd0c29ab3a",
  measurementId: "G-5RJJZEW11B",
};

const theme = createTheme({
  typography: {
    fontFamily: `"Prompt", sans-serif`,
  },
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* Header */}
        <Header />

        {/* Navbar menu */}
        <Navbar />

        {/* basket products */}
        <Basket />

        {/* content */}
        <Routes>
          <Route exact path="/" element={<Home app={app} />} />
          <Route path="/category" element={<Category app={app} />} />
          <Route
            path="/category/:categoryId"
            element={<Category app={app} />}
          />
          <Route
            path="/category/:categoryId/:categoryIdSub1"
            element={<Category app={app} />}
          />
          <Route
            path="/category/:categoryId/:categoryIdSub1/:categoryIdSub2"
            element={<Category app={app} />}
          />
          <Route path="/new-articles" element={<ArticleNews app={app} />} />
          <Route path="/about-us" element={<AboutUs app={app} />} />
          <Route path="/contact-us" element={<ContactUs app={app} />} />

          <Route path="/product/:id" element={<Product app={app} />} />
          <Route path="/admin" element={<Admin app={app} />} />
          <Route path="/admin-login" element={<AdminLogin app={app} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* footer */}
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
