import { Pagination } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import ColorUse from '../assets/theme/ColorUse';

export default function PaginationCategory({pagination={ total: 12, pageSize: 12, pageNumber: 1 }, onChange=()=>{}, value=1 }) {
    const numpage = pagination.total / pagination.pageSize;
    const newNumpage = numpage > parseInt(numpage.toFixed()) ? parseInt(numpage.toFixed())+1 : parseInt(numpage.toFixed()) 
    // console.log(newNumpage);
    return (
        <Stack>
            <Pagination sx={{
                width: "100%",
                display: "flex",
                justifyContent:"center",
                '& .MuiButtonBase-root.Mui-selected':{
                    backgroundColor: ColorUse.colorPrimary,
                    color:'#fff'
                },
                "& .MuiPagination-ul ":{
                    flexWrap: "nowrap",
                },
                "& .MuiPagination-ul li > .MuiButtonBase-root":{
                    minWidth:{
                        xs: "auto",
                        md: 40,
                    }
                }
            }} count={parseInt(newNumpage)} size={'large'} page={value} onChange={(_, valPage)=>{onChange(valPage)}} shape="rounded" variant="outlined" />
        </Stack>
    )
}
