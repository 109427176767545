import { Box, Paper, Typography } from '@mui/material';
import React from 'react'
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ColorUse from '../../assets/theme/ColorUse';

import Zoom from 'react-medium-image-zoom'
import { makeStyles } from '@mui/styles';

export default function ShelfNews({ data = [] }) {
    return (
        <Swiper
            navigation={false}
            loop={true}
            modules={[Navigation, Autoplay]}
            autoplay={{
                delay: 3500,
            }}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            }}
            className="carousel-custom"
        >
            {data.map((item, idx) => {
                return (
                    <SwiperSlide key={idx}>
                        <CardImage data={item} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    )
}


const CardImage = props => {
    const classes = useClasses();
    const { data } = props;
    return (
        <Paper
            elevation={0}
            sx={[styleCard]}
        >
            <Box sx={{
                '& [data-rmiz]': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& [data-rmiz-content]': {
                    }
                }
            }}>
                <Zoom
                    zoomMargin={20}
                    classDialog={classes.backdropAppZoom}
                >
                    <img src={data?.img} alt={data?.img}
                        style={{
                            maxWidth: "100%",
                            width: "100%",
                            objectFit: "cover",
                            transition: 'all 0.25s',
                        }}
                        className="image-new-app"
                    />
                    <Typography className="text-detail" style={{fontSize: "smaller"}}>
                        {/* {data?.detail || ""} */}
                    </Typography>
                </Zoom>
            </Box>
        </Paper>
    )
}

const useClasses = makeStyles(() => ({
    backdropAppZoom: {
        '& [data-rmiz-modal-overlay="visible"]': {
            background: "rgba(0,0,0,0.75)"
        },
    }
}));

const styleCard = {
    // width: '100%',
    cursor: "pointer",
    transition: "all 0.35s",
    p: 0,
    backgroundColor: "#0000",
    "& .image-new-app": {
        border: "1px solid #efefef",
        borderRadius: 1,
        height: {
            xs: 200,
            lg: 180
        }
    },
    '& [data-rmiz-content="found"]':{
        width: "100%",
    },
    ":hover": {
        //   transform: "translate(0px, -5px)",
        transform: "scale(1.005)",
        // border: '1px solid '+ColorUse.colorPrimary,
        // boxShadow: "2px 5px 10px 0px rgba(0,0,0,0.05)",
        "& .t-text-wrap-3": {
            color: ColorUse.colorPrimary,
        },
        '& img': {
            transform: "scale(1.005)",
        }
    },
};