import { Box, Grid, Typography, Stack } from "@mui/material";
import React from "react";
import Details from "../components/product/Details";
import Gallery from "../components/product/Gallery";
import ProductsSlide from "../components/product/ProductsSlide";
import { getProductById, getProducts } from "../services/product_service";
import { getCategory } from "../services/category_service";
import { getProductbyCategory } from "../services/product";

function Product() {
  const [product, setProduct] = React.useState({});
  const [imgList, setImgList] = React.useState([]);
  const [productRelate, setProductRelate] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    const pathname = window.location.pathname.split("/product/");
    setLoader(true);
    getProductById(pathname[1]).then((res) => {
      let concatCategory = "";
      res.category.map((concat, idx) => {
        concatCategory += concat + (res.category.length - 1 === idx ? "" : ",");
      });
      getProductbyCategory(concatCategory, 1, 10, true).then((row) => {
        setProductRelate(row.data);
        setLoader(false);
      });
      getCategory().then((cates) => {
        setProduct({
          title: res.name,
          price: res.price,
          details: res.description,
          column: res.tableColumn,
          list: res.tableRow,
          category: res.category.map((cate, idx) => {
            return cates.find((obj) => obj.id === cate);
          }),
          catalog: res.catalog,
        });
        setImgList(res.image);
       
      });
    });
  }, []);

  const handleNewProduct = (id) => {
    getProductById(id).then((res) => {
      getCategory().then((cates) => {
        setProduct({
          title: res.name,
          price: res.price,
          details: res.description,
          column: res.tableColumn,
          list: res.tableRow,
          category: res.category.map((cate, idx) => {
            return cates.find((obj) => obj.id === cate);
          }),
          catalog: res.catalog,
        });
        setImgList(res.image);
      });
    });
  };

  return (
    <Box
      className="container-main"
      sx={{ paddingX: { xs: 3, xl: 5 }, paddingY: { xs: 3, xl: 5 } }}
    >
      <Grid container justifyContent={"center"}>
        {loader && (
          <Grid
            item
            xs={12}
            mb={{
              xs: 5,
              xl: 3,
            }}
            sx={{
              width: "100%",
              height: "80vh",
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{
                height: "100%",
              }}
            >
              <img
                width={200}
                height={"auto"}
                className="blink_me"
                src="https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/logo%20new.png?alt=media&token=bcf2bba0-e8a6-4f19-ada9-2b6b1c5afd21"
              />
            </Stack>
          </Grid>
        )}
        {!loader && (
          <>
            <Grid
              item
              xs={12}
              sm={7}
              lg={6}
              xl={5}
              sx={{ px: { xs: 2, md: 4 }, mb: { xs: 5, xl: 3 } }}
            >
              <Gallery data={imgList} />
            </Grid>
            <Grid item xs={12} lg={6} xl={7} sx={{ px: { xs: 2, md: 4 } }}>
              <Details
                title={product.title}
                tableHead={product.column}
                list={product.list}
                details={product.details}
                category={product.category}
                catalog={product.catalog}
              />
            </Grid>
            <Grid item xs={12} mt={5}>
              <Typography
                sx={{
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "600",
                  color: "#444",
                  borderBottom: "1px solid #e0e0e0",
                  pb: 1,
                }}
              >
                Related products
              </Typography>
              <ProductsSlide
                productRelate={productRelate}
                handleNewProduct={handleNewProduct}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default Product;
