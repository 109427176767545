import React from 'react'
import {Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Box, ImageListItem } from '@mui/material';

function BrandSlide(props) {
  return (
    <Swiper 
    loop={true}
    modules={[Autoplay]} 
    autoplay={{
      delay: 4500
    }}
    breakpoints={{
    0: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    768: {
        slidesPerView: 3,
        spaceBetween: 10,
    },
    1024: {
        slidesPerView: 5,
        spaceBetween: 20,
    },
    }}
    className="carousel-custom">
      {props.data.map((item, idx)=>{return (
        <SwiperSlide key={idx}>
          <Box sx={{height: {xs:80, lg: 100, xl:150}, width: '100%', display:'flex', justifyContent:"center"}}>
              <img src={item.img} style={{ maxWidth: "100%", height:"100%", objectFit: "contain"}} alt='logo' loading='lazy' />
          </Box>
        </SwiperSlide>
      )})}
    </Swiper>
  )
}

export default BrandSlide