import { AccessTime, Call, MailOutline, Star } from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  ImageListItem,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Container,
  Link,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import HomeCate from "../components/home/HomeCate";
import "swiper/css/pagination";
import "swiper/css/navigation";
import HomeBanner from "../components/home/HomeBanner";
import ShelfSlide from "../components/home/ShelfSlide";
import BrandSlide from "../components/home/BrandSlide";
import { getProductbyCategory } from "../services/product";
import ShelfPromotion from "../components/home/ShelfPromotion";
import jsonPromotion from "../assets/promotion.json";
import ShelfNews from "../components/home/ShelfNews";

const dataContact = {
  name: "APK Scientific",
  address_th: "88/20 หมู่ 2 ตำบลไร่ขิง อำเภอสามพราน จังหวัดนครปฐม 73210",
  address_en: "88/20 Moo 2, Rai Khing , Sam Phran , Nakhon Pathom 73210",
  tel: "034-103-314",
  tel2: "099-421-6688",
  line: "@866tfslh",
  email: "marketing@apk-scientific.com",
};

// Data banner
const banner = [
  {
    id: 1,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/S__4956172.jpg?alt=media&token=b50054c1-6f4a-4972-be68-a972730c8845",
  },
  // {
  //   id: 2,
  //   route: "#",
  //   img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/Banner%202.png?alt=media&token=66fcde7c-7ad7-4dcf-9138-e9d1bc4a65b9",
  // },
  // {
  //   id: 3,
  //   route: "#",
  //   img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/Banner%203.png?alt=media&token=706be8da-5e5e-4fe9-8a56-aae142643bb9",
  // },
];

// News
const data_News = [
  {
    id: 1,
    action: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/promote%2F633962.jpg?alt=media&token=c48f7feb-21d0-4208-bd51-71a3593f93fb",
    detail:
      "Lizards are a widespread group of squamate reptiles, with over 6,000 species.",
  },
  {
    id: 1,
    action: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/promote%2F633963.jpg?alt=media&token=e846e8e2-7e2f-41bd-9e17-ab2d5b544e81",
    detail:
      "Lizards are a widespread group of squamate reptiles, with over 6,000 species.",
  },
  {
    id: 1,
    action: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/promote%2F633964.jpg?alt=media&token=d2709836-e76d-44f4-9baf-3e041548fba1",
    detail:
      "Lizards are a widespread group of squamate reptiles, with over 6,000 species.",
  },
  {
    id: 1,
    action: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/promote%2F635334.jpg?alt=media&token=5a6e741a-736b-4deb-b414-7a866b8ebcce",
    detail:
      "Lizards are a widespread group of squamate reptiles, with over 6,000 species.",
  },
  {
    id: 1,
    action: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/promote%2F635335.jpg?alt=media&token=1c3ef67a-b63e-481d-ba5d-b8ca8f2239b0",
    detail:
      "Lizards are a widespread group of squamate reptiles, with over 6,000 species.",
  },
  {
    id: 1,
    action: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/promote%2F635336.jpg?alt=media&token=69dbd2fd-5d5e-4196-bdb7-d47d4e831ea6",
    detail:
      "Lizards are a widespread group of squamate reptiles, with over 6,000 species.",
  },
];

// Brand
const brand_products = [
  {
    id: 1,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2F1.png?alt=media&token=5f206108-95c7-49e9-b573-717ce0a755d8",
  },
  {
    id: 2,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2F2.png?alt=media&token=289c69af-6806-4faf-aaef-60a18cf75c63",
  },
  {
    id: 3,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2F694x422_HB_news.jpg?alt=media&token=d184f341-5ffd-412f-adff-3a8cf069b8c7",
  },
  {
    id: 4,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2FBuchi-logo.jpg?alt=media&token=400bb905-2755-4edd-8361-f8bf45968634",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2FIKA_Logo.jpg?alt=media&token=e9a008c2-21f5-43c5-b123-59a0ba9dc781",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2FLAUDA_Logo_Neues_Corporate_design.png?alt=media&token=2aa74ed0-95a8-4fcd-84c9-1466dc101ce8",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2FMAKCD-00457.png?alt=media&token=371b20e1-5ab3-4bfe-9706-a70bc36e3592",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2FNovasina-logo-GMYK-300-190-45.jpg?alt=media&token=eb12c8a0-0ec0-4277-8839-bd53213fefb9",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fdownload%20(1).png?alt=media&token=f9dcb258-693f-4e5c-9019-3fc0865c1918",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fdownload%20(3).png?alt=media&token=bb4f829f-662f-429a-9fe6-a13d76f9c887",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fdownload%20(4).png?alt=media&token=42136223-f06d-4ab6-9115-dab146e58f0d",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fdownload%20(5).png?alt=media&token=c1e226d9-3c04-4a95-8128-92de022e8f16",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fdownload.png?alt=media&token=a4a6f4f1-f9b7-414c-8476-7c85d2f64514",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fhs9kuj34vslhfwdtxpyz.png?alt=media&token=bed749aa-008c-4b1e-ad2f-b4928f297b04",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fmemmert-logo-F32A42FAB8-seeklogo.com.png?alt=media&token=1c448742-46af-4578-8e26-b2f64771fa17",
  },
  {
    id: 5,
    route: "#",
    img: "https://firebasestorage.googleapis.com/v0/b/apk-scientific.appspot.com/o/brand%2Fsartorius-logo-gelb-presse-jpg-137727-.jpg?alt=media&token=369b0dcd-15c7-46b0-a4ab-a3d00e301780",
  },
];

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

// === function main ===
function Home() {
  const [productsBest, setProductsBest] = useState([]);
  useEffect(() => {
    getProductbyCategory("", 1, 12, false, false).then((res) => {
      setProductsBest(shuffle(res.data?.slice(0, 12)));
    });
  }, []);

  return (
    <Box>
      <Box
        sx={{
          background: "linear-gradient(to right, #efefef , #fefefe)",
          paddingY: 0,
          paddingTop: 0,
        }}
      >
        <Container sx={{ maxWidth: { lg: 1300 } }}>
          <Grid container>
            <Grid item xs={12} md={12} alignSelf="center">
              <HomeBanner data={banner} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        className="container-main"
        sx={{ paddingX: { xs: 3, xl: 5 }, paddingY: { xs: 3, xl: 5 } }}
      >
        {/* phase 1 */}
        <Container sx={{ paddingX: { xs: 0, md: 3 } }}>
          <Grid container spacing={2} sx={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Box>
                <Grid
                  container
                  justifyContent={"center"}
                  className="link-home-social"
                >
                  <Grid item xs={12} sm={6} lg={2.4} sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "start", lg: "center" },
                      }}
                    >
                      <Call sx={{ fontSize: 38, color: "#1e73be" }} />
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          component={"p"}
                          sx={{
                            fontSize: {
                              xs: 14,
                              md: 16,
                              color: "#1e73be",
                              fontWeight: 600,
                            },
                          }}
                        >
                          โทรสั่งซื้อสินค้า
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{ fontSize: { xs: 12, md: 13 } }}
                          noWrap={true}
                        >
                          {dataContact.tel}, {dataContact.tel2}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3.2} sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "start", lg: "center" },
                      }}
                    >
                      <MailOutline sx={{ fontSize: 38, color: "#dd8833" }} />
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          component={"p"}
                          sx={{
                            fontSize: {
                              xs: 14,
                              md: 16,
                              color: "#dd8833",
                              fontWeight: 600,
                            },
                          }}
                        >
                          อีเมล์
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{ fontSize: { xs: 12, md: 13 } }}
                          noWrap={true}
                        >
                          {dataContact.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2.2} sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "start", lg: "center" },
                        textDecoration: "none",
                        color: "#333",
                      }}
                      component={"a"}
                      href={"https://web.facebook.com/apkscientific"}
                      target={"_blank"}
                    >
                      <ImageListItem>
                        <img
                          src="../assets/images/android-icon-192x192.png"
                          style={{ width: 32, height: 32 }}
                          alt="icon"
                        />
                      </ImageListItem>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          component={"p"}
                          sx={{
                            fontSize: {
                              xs: 14,
                              md: 16,
                              color: "#38599a",
                              fontWeight: 600,
                            },
                          }}
                        >
                          Facebook Official
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{ fontSize: { xs: 14, md: 14 } }}
                        >
                          APK Scientific จัดจำหน่ายเครื่องมือวิทยาศาสตร์
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2.2} sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "start", lg: "center" },
                        textDecoration: "none",
                        color: "#333",
                      }}
                      component={"a"}
                      href={"https://line.me/R/ti/p/%40866tfslh#~"}
                      target={"_blank"}
                    >
                      <ImageListItem>
                        <img
                          src="https://snp-scientific.com/wp-content/uploads/2022/02/icon-line.png"
                          style={{ width: 32, height: 32 }}
                          alt="icon"
                        />
                      </ImageListItem>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          component={"p"}
                          sx={{
                            fontSize: {
                              xs: 14,
                              md: 16,
                              color: "#39cd00",
                              fontWeight: 600,
                            },
                          }}
                        >
                          LINE OFFICIAL
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{ fontSize: { xs: 12, md: 13 } }}
                        >
                          {dataContact.line}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "start", lg: "center" },
                      }}
                    >
                      <AccessTime sx={{ fontSize: 38, color: "#010a75" }} />
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          component={"p"}
                          sx={{
                            fontSize: {
                              xs: 14,
                              md: 16,
                              color: "#010a75",
                              fontWeight: 600,
                            },
                          }}
                        >
                          เวลาทำการ
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{ fontSize: { xs: 12, md: 13 } }}
                        >
                          จ.-ศ. 8.00-17.00
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* phase 2 */}
        <Container sx={{ paddingX: { xs: 0, md: 3 } }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ mb: 4 }}>
                <Typography
                  component={"h3"}
                  variant={"h3"}
                  sx={{
                    fontSize: { xs: 18, md: 22, xl: 26 },
                    color: "#39cd00",
                    borderBottom: "0.75px solid #d0d0d0",
                    pb: 2,
                    mb: 2,
                  }}
                >
                  สินค้าแนะนำ
                </Typography>
                <ShelfSlide data={productsBest} />
              </Box>

              {/* Promotion */}
              <Box sx={{ mb: 4 }}>
                <Typography
                  component={"h3"}
                  variant={"h3"}
                  sx={{
                    fontSize: { xs: 18, md: 22, xl: 26 },
                    color: "#d4001a",
                    borderBottom: "0.75px solid #d0d0d0",
                    pb: 2,
                    mb: 2,
                  }}
                >
                  โปรโมชัน
                </Typography>
                {/* <ShelfSlide data={productsPromotion} /> */}
                <ShelfPromotion data={jsonPromotion} />
              </Box>

              {/* News */}
              {/* <Box sx={{ mb: 4 }}>
                <Typography
                  component={"h3"}
                  variant={"h3"}
                  sx={{
                    fontSize: { xs: 18, md: 22, xl: 26 },
                    color: "#d4001a",
                    borderBottom: "0.75px solid #d0d0d0",
                    pb: 2,
                    mb: 2,
                  }}
                >
                  ข่าวสารและกิจกรรม
                </Typography>
                <ShelfNews data={data_News} />
              </Box> */}

              {/* Brand Products */}
              <Box sx={{ mb: 4 }}>
                <Typography
                  component={"h3"}
                  variant={"h3"}
                  sx={{
                    fontSize: { xs: 18, md: 22, xl: 26 },
                    color: "#f1132a",
                    borderBottom: "0.75px solid #d0d0d0",
                    pb: 2,
                    mb: 2,
                  }}
                >
                  แบรนด์สินค้า
                </Typography>
                <BrandSlide data={brand_products} />
              </Box>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={4}
              xl={3}
              sx={{ borderRadius: 1, overflow: "hidden" }}
            >
              <Box>
                <ImageListItem sx={{ width: "100%", height: 485 }}>
                  <img
                    src="https://snp-scientific.com/wp-content/uploads/2022/02/catalog-2022.jpg"
                    style={{ width: "100%", maxHeight: "100%" }}
                    alt=""
                  />
                </ImageListItem>
              </Box>
              <Box>
                <ImageListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="https://snp-scientific.com/wp-content/uploads/2022/02/catalog-qr-150x150.png"
                    style={{ width: "auto", height: "auto" }}
                    alt=""
                  />
                </ImageListItem>
              </Box>
              <Box>
                <ImageListItem sx={{ width: "100%", height: 485 }}>
                  <img
                    src="https://snp-scientific.com/wp-content/uploads/2022/02/Calendar2022-SNP-1.jpg"
                    style={{ width: "100%", maxHeight: "100%" }}
                    alt=""
                  />
                </ImageListItem>
              </Box>
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
export default Home;
