import { PictureAsPdfOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorUse from "../../assets/theme/ColorUse";
import Readmore from "../../Utils/ReadMore";

// ======================== function main
function Details({
  title = "",
  list = [{ id: "", name: "", size: "" }],
  details = "",
  tableHead = [],
  category = [],
  catalog = "",
}) {
  const navigate = useNavigate();

  console.log(catalog);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} mb={1}>
          <Typography variant={"h4"} sx={{ color: ColorUse.colorPrimary }}>
            {title.split("@b")[0]}
          </Typography>

          {/* <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHead.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{ border: "1px solid #e0e0e0", fontSize: 16 }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row, idx) => {
                  if (row.length > 0) {
                    const splitData = row.split(",");
                    return (
                      <TableRow>
                        {splitData.map((item, index) => (
                          <TableCell
                            sx={{
                              border: "1px solid #e0e0e0",
                              color: ColorUse.colorGray,
                            }}
                          >
                            {row}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Grid>

        {/* details */}
        <Grid item xs={12} mb={3}>
          {/* {details !== "" && (
            <Readmore
              style={{
                "white-space": "pre-wrap",
              }}
            >
              {details}
            </Readmore>
          )} */}
          {details !== "" && (
            <Typography
              sx={{
                "white-space": "pre-wrap",
              }}
            >
              {details}
            </Typography>
          )}
        </Grid>

        {/* categories */}
        <Grid
          item
          xs={12}
          mb={3}
          sx={{ display: { sm: "flex" } }}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: 13, marginRight: 0.5 }}>
            CATEGORIES :{" "}
          </Typography>
          <Typography sx={{ fontSize: 13 }}>
            {category.map((obj, idx) => {
              return (
                <Typography
                  className="t-btn-link-category"
                  key={idx}
                  component={"span"}
                  onClick={() => {
                    navigate("/category/" + obj.id);
                  }}
                >
                  {obj.category_name}
                  {idx === 2 ? "" : ", "}
                </Typography>
              );
            })}
          </Typography>
        </Grid>

        {/* Button */}
        <Grid item xs={12} mb={3} display="flex" alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} mr={1}>
            {catalog && (<Button
              size="large"
              href={catalog}
              target={"_blank"}
              sx={{
                background: ColorUse.colorPrimary,
                border: "2px solid " + ColorUse.colorPrimary,
                color: "#fff",
                "&:hover": {
                  color: ColorUse.colorPrimary,
                  background: "#0000",
                },
              }}
            >
              <PictureAsPdfOutlined sx={{ marginRight: 0.5 }} />{" "}
              ดาวน์โหลดแคตตาล็อก
            </Button>)}
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Button
              size="large"
              href={"https://line.me/R/ti/p/%40866tfslh#~"}
              target={"_blank"}
              sx={{
                background: "#00b900",
                border: "2px solid #00b900",
                color: "#fff",
                "&:hover": {
                  color: "#00b900",
                  background: "#0000",
                },
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-line"
                style={{ marginRight: 6 }}
                viewBox="0 0 16 16"
              >
                <path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z" />
              </svg>
              ติดต่อเซลล์
            </Button>
          </Box>
        </Grid>

        {/* image brand */}
        {/* <Grid item xs={12} mb={3}>
          <ImageListItem>
            <img
              src={
                "https://snp-scientific.com/wp-content/uploads/2021/02/Hirschmann-lOGO-1.png"
              }
              style={{ width: 180 }}
              className={"t-img-fluid"}
              alt="brand"
            />
          </ImageListItem>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default Details;
